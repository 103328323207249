import { useState, useEffect } from 'react'
import axios from 'axios'

const useFetch = (fetchFunction, params) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (params) {
      const abortController = new AbortController()
      const abortSignal = abortController.signal

      fetchData(params, abortSignal)

      return () => {
        abortController.abort()
      }
    }
  }, [params])

  const fetchData = async (params, abortSignal) => {
    setIsError(false)
    setIsLoading(true)
    setData(null)

    try {
      const response = await fetchFunction(params, abortSignal)
      setData(response)
      setIsLoading(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        console.error('Impact Dashboard - request canceled')
      } else {
        console.error(error)
        setIsError(true)
        setIsLoading(false)
      }
    }
  }

  return {
    data,
    isLoading,
    isError,
    fetchData
  }
}

export default useFetch
