import { TRANSLATION_VALUE_KEYS } from './constants'

const shared = {
  'en-US': {
    [TRANSLATION_VALUE_KEYS.send]: 'Send',
    [TRANSLATION_VALUE_KEYS.sending]: 'Sending',
    [TRANSLATION_VALUE_KEYS.done]: 'Done',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'Your email',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'your@email.here',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Type your message here',
    [TRANSLATION_VALUE_KEYS.email]: 'Email',
    [TRANSLATION_VALUE_KEYS.to]: 'To',
    [TRANSLATION_VALUE_KEYS.firstName]: 'First name',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Last name',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} search results',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: 'No search results were found',
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'Share board',
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Thank You',
    [TRANSLATION_VALUE_KEYS.headline]: 'Title',
    [TRANSLATION_VALUE_KEYS.company]: 'Company',
    [TRANSLATION_VALUE_KEYS.phone]: 'Phone Number',
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'This page requires you to verify your email address',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Enter your email to request a confirmation code',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Enter your email',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Continue to Page',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Send a new code',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'New code sent',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: 'The confirmation code has been sent to you. Please check for an email from',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: 'Note that the email may be in your promotions, updates, or spam folder.',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: 'This email address is not authorized to view this page',
    [TRANSLATION_VALUE_KEYS.accessPageError]: 'Sorry you are having trouble accessing this page',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: 'Please check with your company representative to gain access',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Enter your confirmation code',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Type code here',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Incorrect code',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'An Error Occurred',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: 'The %{fieldName} field is required.',
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'This Page is password-protected',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Please enter the password below',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Enter the password here',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Verify Password',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Wrong password',
    [TRANSLATION_VALUE_KEYS.emailField]: 'Please check the email format',
    [TRANSLATION_VALUE_KEYS.code]: 'Code'

  },
  'de-DE': {
    [TRANSLATION_VALUE_KEYS.send]: 'Absenden',
    [TRANSLATION_VALUE_KEYS.sending]: 'Sende',
    [TRANSLATION_VALUE_KEYS.done]: 'Fertig',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'Ihre E-Mail',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'ihre@email.hier',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Tragen Sie Ihre Nachricht hier ein',
    [TRANSLATION_VALUE_KEYS.email]: 'E-Mail',
    [TRANSLATION_VALUE_KEYS.to]: 'An',
    [TRANSLATION_VALUE_KEYS.firstName]: 'Vorname',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Nachname',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} Suchergebnisse',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: 'Es wurden keine Suchergebnisse gefunden',
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'Board teilen',
    [TRANSLATION_VALUE_KEYS.headline]: 'Titel',
    [TRANSLATION_VALUE_KEYS.company]: 'Unternehmen',
    [TRANSLATION_VALUE_KEYS.phone]: 'Telefonnummer',
    [TRANSLATION_VALUE_KEYS.emailField]: 'Bitte überprüfen Sie das E-Mail-Format',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: 'Das %{fieldName} Feld muss ausgefüllt sein.',
    [TRANSLATION_VALUE_KEYS.code]: 'Code'
  },
  'fr-CA': {
    [TRANSLATION_VALUE_KEYS.send]: 'Envoyer',
    [TRANSLATION_VALUE_KEYS.sending]: 'Envoi',
    [TRANSLATION_VALUE_KEYS.done]: 'Terminé',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'Votre e-mail',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'votre@email.ici',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Tapez votre message ici',
    [TRANSLATION_VALUE_KEYS.email]: 'e-mail',
    [TRANSLATION_VALUE_KEYS.to]: 'À',
    [TRANSLATION_VALUE_KEYS.firstName]: 'Prénom',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Nom',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} résultats de recherche',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: "Aucun résultat de recherche n'a été trouvé",
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'Partager le panneau',
    [TRANSLATION_VALUE_KEYS.headline]: 'Titre',
    [TRANSLATION_VALUE_KEYS.company]: 'Société',
    [TRANSLATION_VALUE_KEYS.phone]: 'Numéro de téléphone',
    [TRANSLATION_VALUE_KEYS.emailField]: 'Veuillez vérifier le format de votre adresse e-mail',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: 'Le champ %{fieldName} est requis.',
    [TRANSLATION_VALUE_KEYS.code]: 'code'
  },
  'nl-NL': {
    [TRANSLATION_VALUE_KEYS.send]: 'Versturen',
    [TRANSLATION_VALUE_KEYS.sending]: 'Versturen',
    [TRANSLATION_VALUE_KEYS.done]: 'Klaar',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'Je e-mailadres',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'jouw@email.hier',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Typ je boodschap hier',
    [TRANSLATION_VALUE_KEYS.email]: 'e-mailadres',
    [TRANSLATION_VALUE_KEYS.to]: 'Aan',
    [TRANSLATION_VALUE_KEYS.firstName]: 'Voornaam',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Achternaam',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} zoekresultaten',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: 'Geen zoekresultaten gevonden',
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'Bord delen',
    [TRANSLATION_VALUE_KEYS.headline]: 'Titel',
    [TRANSLATION_VALUE_KEYS.company]: 'Firma',
    [TRANSLATION_VALUE_KEYS.phone]: 'Telefoonnummer',
    [TRANSLATION_VALUE_KEYS.emailField]: 'Controleer het formaat van het e-mailadres',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: 'Het veld voor het %{fieldName} is verplicht.',
    [TRANSLATION_VALUE_KEYS.code]: 'code'
  },
  'pt-BR': {
    [TRANSLATION_VALUE_KEYS.send]: 'Enviar',
    [TRANSLATION_VALUE_KEYS.sending]: 'Enviando',
    [TRANSLATION_VALUE_KEYS.done]: 'Concluído',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'Seu e-mail',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'seu@email.aqui',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Digite sua mensagem aqui',
    [TRANSLATION_VALUE_KEYS.email]: 'e-mail',
    [TRANSLATION_VALUE_KEYS.to]: 'Para',
    [TRANSLATION_VALUE_KEYS.firstName]: 'Nome',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Sobrenome',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} resultados da busca',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: 'Não foram encontrados resultados',
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'Compartilhar quadro',
    [TRANSLATION_VALUE_KEYS.headline]: 'Cargo',
    [TRANSLATION_VALUE_KEYS.company]: 'Empresa',
    [TRANSLATION_VALUE_KEYS.phone]: 'Número de telefone',
    [TRANSLATION_VALUE_KEYS.emailField]: 'Verifique o formato do e-mail',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: 'O campo %{fieldName} é obrigatório.',
    [TRANSLATION_VALUE_KEYS.code]: 'código'
  },
  'fr-FR': {
    [TRANSLATION_VALUE_KEYS.send]: 'Envoyer',
    [TRANSLATION_VALUE_KEYS.sending]: "En cours d'envoi",
    [TRANSLATION_VALUE_KEYS.done]: 'Fait',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'Votre adresse e-mail',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'votre@adresseemail.ici',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Tapez votre message ici',
    [TRANSLATION_VALUE_KEYS.email]: 'adresse e-mail',
    [TRANSLATION_VALUE_KEYS.to]: 'À',
    [TRANSLATION_VALUE_KEYS.firstName]: 'Prénom',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Nom',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} résultats de recherche',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: "Aucun résultat de recherche n'a été trouvé",
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'Partager le panneau',
    [TRANSLATION_VALUE_KEYS.headline]: 'Titre',
    [TRANSLATION_VALUE_KEYS.company]: 'Société',
    [TRANSLATION_VALUE_KEYS.phone]: 'Numéro de téléphone',
    [TRANSLATION_VALUE_KEYS.emailField]: "Veuillez vérifier le format de l'adresse e-mail",
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: 'Le champ %{fieldName} est requis.',
    [TRANSLATION_VALUE_KEYS.code]: 'code'
  },
  'it-IT': {
    [TRANSLATION_VALUE_KEYS.send]: 'Invia',
    [TRANSLATION_VALUE_KEYS.sending]: 'Invio...',
    [TRANSLATION_VALUE_KEYS.done]: 'Fatto',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'La tua email',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'la tua @email qui',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Scrivi qui il tuo messaggio',
    [TRANSLATION_VALUE_KEYS.email]: 'email',
    [TRANSLATION_VALUE_KEYS.to]: 'A',
    [TRANSLATION_VALUE_KEYS.firstName]: 'Nome',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Cognome',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} risultati di ricerca',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: 'Nessun risultato di ricerca trovato',
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'condividi board',
    [TRANSLATION_VALUE_KEYS.headline]: 'Titolo',
    [TRANSLATION_VALUE_KEYS.company]: 'Società',
    [TRANSLATION_VALUE_KEYS.phone]: 'Numero di telefono',
    [TRANSLATION_VALUE_KEYS.emailField]: "Controlla il formato dell'email",
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: 'Il campo %{fieldName} è obbligatorio.',
    [TRANSLATION_VALUE_KEYS.code]: 'codice'
  },
  'tr': {
    [TRANSLATION_VALUE_KEYS.send]: 'Gönder',
    [TRANSLATION_VALUE_KEYS.sending]: 'Gönderiliyor',
    [TRANSLATION_VALUE_KEYS.done]: 'Bitti',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'E-posta',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'e-posta buraya',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Mesajınızı buraya yazın',
    [TRANSLATION_VALUE_KEYS.email]: 'E-posta',
    [TRANSLATION_VALUE_KEYS.to]: 'Alıcı',
    [TRANSLATION_VALUE_KEYS.firstName]: 'Adı',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Soyadı',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} arama sonuçları',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: 'Arama sonucu bulunmadı',
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'Board paylaş',
    [TRANSLATION_VALUE_KEYS.headline]: 'Unvan',
    [TRANSLATION_VALUE_KEYS.company]: 'Şirket',
    [TRANSLATION_VALUE_KEYS.phone]: 'Telefon Numarası',
    [TRANSLATION_VALUE_KEYS.emailField]: 'Lütfen e-posta biçimini kontrol edin',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: '%{fieldName} alanı gerekli.',
    [TRANSLATION_VALUE_KEYS.code]: 'kod'
  },
  'no': {
    [TRANSLATION_VALUE_KEYS.send]: 'Send',
    [TRANSLATION_VALUE_KEYS.sending]: 'Sender',
    [TRANSLATION_VALUE_KEYS.done]: 'Ferdig',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'E-postadressen din',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'din@epostadresse.her',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Skriv meldingen din her',
    [TRANSLATION_VALUE_KEYS.email]: 'E-postadressen',
    [TRANSLATION_VALUE_KEYS.to]: 'Til',
    [TRANSLATION_VALUE_KEYS.firstName]: 'Fornavn',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Etternavn',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} søkeresultater',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: 'Fant ingen søkeresultater',
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'Del brettet',
    [TRANSLATION_VALUE_KEYS.headline]: 'Tittel',
    [TRANSLATION_VALUE_KEYS.company]: 'Bedrift',
    [TRANSLATION_VALUE_KEYS.phone]: 'Telefonnummer',
    [TRANSLATION_VALUE_KEYS.emailField]: 'Sjekk e-postformatet',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: '%{fieldName} feltet er påkrevd.',
    [TRANSLATION_VALUE_KEYS.code]: 'kode'
  },
  'fi': {
    [TRANSLATION_VALUE_KEYS.send]: 'Lähetä',
    [TRANSLATION_VALUE_KEYS.sending]: 'Lähetetään',
    [TRANSLATION_VALUE_KEYS.done]: 'Valmis',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'Sähköpostiosoitteesi',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'your@email.here',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Kirjoita viestisi tähän',
    [TRANSLATION_VALUE_KEYS.email]: 'Sähköposti',
    [TRANSLATION_VALUE_KEYS.to]: 'Vastaanottaja',
    [TRANSLATION_VALUE_KEYS.firstName]: 'Etunimi',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Sukunimi',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} hakutulokset',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: 'Hakutuloksia ei löytynyt',
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'Jaa taulu',
    [TRANSLATION_VALUE_KEYS.headline]: 'Titteli',
    [TRANSLATION_VALUE_KEYS.company]: 'Yhtiö',
    [TRANSLATION_VALUE_KEYS.phone]: 'Puhelinnumero',
    [TRANSLATION_VALUE_KEYS.code]: 'koodi',
    [TRANSLATION_VALUE_KEYS.emailField]: 'Tarkista sähköpostiosoitteen muoto',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: '%{fieldName} kenttä on pakollinen.'
  },
  'sv': {
    [TRANSLATION_VALUE_KEYS.send]: 'Skicka',
    [TRANSLATION_VALUE_KEYS.sending]: 'Skickar',
    [TRANSLATION_VALUE_KEYS.done]: 'Klar',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'Din e-post',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'din@e-post.här',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Skriv ditt meddelande här',
    [TRANSLATION_VALUE_KEYS.email]: 'E-post',
    [TRANSLATION_VALUE_KEYS.to]: 'Till',
    [TRANSLATION_VALUE_KEYS.firstName]: 'Förnamn',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Efternamn',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} sökresultat',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: 'Inga sökresultat hittades',
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'Dela tavla',
    [TRANSLATION_VALUE_KEYS.headline]: 'Titel',
    [TRANSLATION_VALUE_KEYS.company]: 'Företag',
    [TRANSLATION_VALUE_KEYS.phone]: 'Telefonnummer',
    [TRANSLATION_VALUE_KEYS.emailField]: 'Tarkista sähköpostiosoitteen muoto',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: '%{fieldName} kenttä on pakollinen.',
    [TRANSLATION_VALUE_KEYS.emailField]: 'Vänligen kontrollera e-postformatet',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: '%{fieldName} är obligatoriskt.',
    [TRANSLATION_VALUE_KEYS.code]: 'kod'
  },
  'da': {
    [TRANSLATION_VALUE_KEYS.send]: 'Send',
    [TRANSLATION_VALUE_KEYS.sending]: 'Sender',
    [TRANSLATION_VALUE_KEYS.done]: 'Færdig',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'Din e-mail',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'din@e-mail.her',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Skriv din besked her',
    [TRANSLATION_VALUE_KEYS.email]: 'E-mail',
    [TRANSLATION_VALUE_KEYS.to]: 'Til',
    [TRANSLATION_VALUE_KEYS.firstName]: 'Fornavn',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Efternavn',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} søgeresultater',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: 'Ingen søgeresultater fundet',
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'Del board',
    [TRANSLATION_VALUE_KEYS.headline]: 'Titel',
    [TRANSLATION_VALUE_KEYS.company]: 'Virksomhed',
    [TRANSLATION_VALUE_KEYS.phone]: 'Telefonnummer',
    [TRANSLATION_VALUE_KEYS.emailField]: 'Kontroller e-mail-formatet',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: '%{fieldName} skal udfyldes.',
    [TRANSLATION_VALUE_KEYS.code]: 'kode'
  },
  'ja': {
    [TRANSLATION_VALUE_KEYS.send]: '送信',
    [TRANSLATION_VALUE_KEYS.sending]: '送信中',
    [TRANSLATION_VALUE_KEYS.done]: '完了',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'メールアドレス',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'your@email.here',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'メッセージを入力してください。',
    [TRANSLATION_VALUE_KEYS.email]: 'メール',
    [TRANSLATION_VALUE_KEYS.to]: '宛先',
    [TRANSLATION_VALUE_KEYS.firstName]: '名',
    [TRANSLATION_VALUE_KEYS.lastName]: '姓',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} 検索結果',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: '検索結果が見つかりませんでした',
    [TRANSLATION_VALUE_KEYS.shareBoard]: '共有ボード',
    [TRANSLATION_VALUE_KEYS.headline]: '役職',
    [TRANSLATION_VALUE_KEYS.company]: '会社名',
    [TRANSLATION_VALUE_KEYS.phone]: '電話番号',
    [TRANSLATION_VALUE_KEYS.emailField]: 'メールの形式をご確認ください',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: '%{fieldName}は必須です。',
    [TRANSLATION_VALUE_KEYS.code]: 'コード'
  },
  'es-ES': {
    [TRANSLATION_VALUE_KEYS.send]: 'Enviar',
    [TRANSLATION_VALUE_KEYS.sending]: 'Enviando',
    [TRANSLATION_VALUE_KEYS.done]: 'Hecho',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'Tu correo electrónico',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'tu@correoelectrónico.aquí',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Escribe tu mensaje aquí',
    [TRANSLATION_VALUE_KEYS.email]: 'Correoelectrónco',
    [TRANSLATION_VALUE_KEYS.to]: 'Para',
    [TRANSLATION_VALUE_KEYS.firstName]: 'Nombre',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Apellidos',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} resultados de búsqueda',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: 'No se encontraron resultados de búsqueda',
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'Compartir panel',
    [TRANSLATION_VALUE_KEYS.headline]: 'Cargo',
    [TRANSLATION_VALUE_KEYS.company]: 'Empresa',
    [TRANSLATION_VALUE_KEYS.phone]: 'Número de teléfono',
    [TRANSLATION_VALUE_KEYS.emailField]: 'Por favor, comprueba el formato del correo electrónico',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: 'El campo %{fieldName} es obligatorio.',
    [TRANSLATION_VALUE_KEYS.code]: 'código'
  },
  'es-419': {
    [TRANSLATION_VALUE_KEYS.send]: 'Enviar',
    [TRANSLATION_VALUE_KEYS.sending]: 'Enviando',
    [TRANSLATION_VALUE_KEYS.done]: 'Listo',
    [TRANSLATION_VALUE_KEYS.yourEmail]: 'Su correo electrónico',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'su@correo.aqui',
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: 'Escriba su mensaje aquí',
    [TRANSLATION_VALUE_KEYS.email]: 'Correo electrónico',
    [TRANSLATION_VALUE_KEYS.to]: 'Para',
    [TRANSLATION_VALUE_KEYS.firstName]: 'Primer nombre',
    [TRANSLATION_VALUE_KEYS.lastName]: 'Apellido',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} resultados de la búsqueda',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: 'No se han encontrado resultados de búsqueda',
    [TRANSLATION_VALUE_KEYS.shareBoard]: 'Compartir tablero',
    [TRANSLATION_VALUE_KEYS.headline]: 'Título',
    [TRANSLATION_VALUE_KEYS.company]: 'Empresa',
    [TRANSLATION_VALUE_KEYS.phone]: 'Número de teléfono',
    [TRANSLATION_VALUE_KEYS.emailField]: 'Verifique el formato de correo electrónico',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: 'El campo %{fieldName} es obligatorio.',
    [TRANSLATION_VALUE_KEYS.code]: 'código'
  },
  'ko-KR': {
    [TRANSLATION_VALUE_KEYS.email]: '이메일',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: '여기에 이메일 주소를 입력하세요',
    [TRANSLATION_VALUE_KEYS.firstName]: '이름',
    [TRANSLATION_VALUE_KEYS.lastName]: '성',
    [TRANSLATION_VALUE_KEYS.shareBoard]: '게시판 공유',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} 검색 결과',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: '검색 결과가 없습니다',
    [TRANSLATION_VALUE_KEYS.headline]: '직함',
    [TRANSLATION_VALUE_KEYS.company]: '회사',
    [TRANSLATION_VALUE_KEYS.phone]: '전화번호',
    [TRANSLATION_VALUE_KEYS.emailField]: '*이메일 주소 형식을 확인하세요.',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: '%{fieldName} 필드는 필수입니다.',
    [TRANSLATION_VALUE_KEYS.code]: '코드'
  },
  'zh-CN': {
    [TRANSLATION_VALUE_KEYS.email]: '电子邮件',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'your@email.here',
    [TRANSLATION_VALUE_KEYS.firstName]: '名字',
    [TRANSLATION_VALUE_KEYS.lastName]: '姓氏',
    [TRANSLATION_VALUE_KEYS.shareBoard]: '分享板',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} 搜索结果',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: '没有找到搜索结果',
    [TRANSLATION_VALUE_KEYS.headline]: '职衔',
    [TRANSLATION_VALUE_KEYS.company]: '公司',
    [TRANSLATION_VALUE_KEYS.phone]: '电话号码',
    [TRANSLATION_VALUE_KEYS.emailField]: '*请检查电子邮箱格式',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: '%{fieldName}不能为空.',
    [TRANSLATION_VALUE_KEYS.code]: '码'
  },
  'zh-TW': {
    [TRANSLATION_VALUE_KEYS.email]: '电子邮件',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: 'your@email.here',
    [TRANSLATION_VALUE_KEYS.firstName]: '名字',
    [TRANSLATION_VALUE_KEYS.lastName]: '姓氏',
    [TRANSLATION_VALUE_KEYS.shareBoard]: '分享版面',
    [TRANSLATION_VALUE_KEYS.searchResults]: '%{resultsCount} 搜尋結果',
    [TRANSLATION_VALUE_KEYS.noSearchResults]: '沒有發現搜索結果',
    [TRANSLATION_VALUE_KEYS.headline]: '標題',
    [TRANSLATION_VALUE_KEYS.company]: '公司',
    [TRANSLATION_VALUE_KEYS.phone]: '電話',
    [TRANSLATION_VALUE_KEYS.emailField]: '*請檢查電子郵件的格式',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: '%{fieldName}不能空白。',
    [TRANSLATION_VALUE_KEYS.code]: '碼'
  }
}

export default shared
