import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedEs419 = shared['es-419']

const es419 = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedEs419.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Creado por'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Hola %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: "Solo haga clic en “ENVIAR” y le contactaremos en breve.",
    [TRANSLATION_VALUE_KEYS.notLeadName]: '¿Usted no es %{leadName}?'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedEs419.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedEs419.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedEs419.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedEs419.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedEs419.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Complete los campos obligatorios',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedEs419.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Complete los campos obligatorios'
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedEs419.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedEs419.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Su mensaje',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedEs419.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Compartir esta página usando:'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: '¿Con quién le gustaría compartirla?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedEs419.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedEs419.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedEs419.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedEs419.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedEs419.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedEs419.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedEs419.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'compartir@correo.aqui',
    [TRANSLATION_VALUE_KEYS.senderDetails]: 'Detalles del remitente'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedEs419.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'Lo más visto'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'Archivo',
    [TRANSLATION_VALUE_KEYS.image]: 'Imagen',
    [TRANSLATION_VALUE_KEYS.link]: 'Enlace',
    [TRANSLATION_VALUE_KEYS.video]: 'Vídeo',
    [TRANSLATION_VALUE_KEYS.article]: 'Artículo'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Buscar'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Inicio'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Introduzca la contraseña',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'La contraseña es incorrecta. Inténtelo de nuevo',
    [TRANSLATION_VALUE_KEYS.go]: 'IR',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'Ingrese su contraseña aquí...',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'Ver contenido'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedEs419.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedEs419.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'Abrir en una nueva pestaña',
    [TRANSLATION_VALUE_KEYS.like]: 'Me gusta',
    [TRANSLATION_VALUE_KEYS.download]: 'Descargar'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'Contenido'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedEs419.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedEs419.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'Correo electrónico',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedEs419.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedEs419.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedEs419.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Mensaje',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedEs419.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedEs419.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedEs419.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedEs419.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedEs419.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedEs419.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'Escriba su mensaje aquí'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'Más',
    [TRANSLATION_VALUE_KEYS.less]: 'Menos'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Gracias'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Debe verificar su correo electrónico para esta página',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Ingrese su correo electrónico para solicitar un código de confirmación',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Ingrese su correo electrónico',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Continuar a la página',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Enviar un código nuevo',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'Se envió un código nuevo',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: 'Se le ha enviado el código de confirmación. Compruebe si hay un correo electrónico de',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: 'Tenga en cuenta que el correo electrónico puede estar en su carpeta de promociones, actualizaciones o correo no deseado.',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: 'Este correo electrónico no está autorizado para ver esta página',
    [TRANSLATION_VALUE_KEYS.accessPageError]: 'Lo sentimos, tiene problemas para acceder a esta página',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: 'Consulte al representante de su compañía para obtener acceso',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Ingrese su código de confirmación',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Escriba el código aquí',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Código incorrecto',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'Se ha producido un error',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedEs419.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedEs419.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedEs419.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedEs419.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'Esta página está protegida por contraseña',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Introduzca la contraseña a continuación',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Ingrese la contraseña aquí',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Verificar contraseña',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Contraseña incorrecta'
  }
}

export default es419
